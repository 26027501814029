<template>
  <div>
    <div class="flex flex-row items-center w-full py-1 px-2 h-50">
      <div class="flex w-tableIndex justify-center mr-2 pr-3">
        <p class="font-normal text-md text-grey-dark-2">
          {{ index + 1 }}
        </p>
      </div>
      <div class="flex flex-grow w-1/4">
        <p class="text-md text-grey-dark-1 font-semibold">
          {{ source.competencyName }}
        </p>
      </div>
      <div class="w-30 mr-5" />
      <div
        v-for="(item, index) in targetsList"
        :key="index"
        class="flex flex-grow w-1/6 justify-center"
      >
        <p
          class="text-md text-grey-dark-2 py-1 px-2.5 rounded"
          :style="colorDifference(index)"
        >
          {{ parseFloat(item.value).toFixed(2) }}
        </p>
      </div>
      <div class="w-30 ml-5" />
    </div>
    <div
      v-if="index !== targets.length - 1"
      class="border border-grey-light-1 border-top-none"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "ListItem",
  props: {
    index: { type: Number },
    source: { type: Object, default: () => ({}) },
    jobRoles: { type: Array, default: () => [] },
    slicingIndex: { type: Number, default: 0 },
  },
  computed: {
    ...mapState({
      targets: (state) => state.assessmentSetup.targets,
      generalInfo: (state) => state.assessmentSetup.generalInfo,
    }),
    isIndividual() {
      return this.generalInfo?.surveyType === 0;
    },
    isOrganizational() {
      return this.generalInfo?.surveyType === 1;
    },
    targetsList() {
      if (this.isIndividual) return this.slicedTargets;
      if (this.isOrganizational) return this.source.targets;
      return [];
    },
    slicedTargets() {
      return this.source.targets
        .filter((el) => this.jobRoles.indexOf(el.jobRoleId) > -1)
        .slice(this.slicingIndex * 3, this.slicingIndex * 3 + 3);
    },
  },
  methods: {
    colorDifference(index) {
      if (
        this.targetsList[index]?.value <
        this.targetsList[index]?.originalValue
      ) {
        return "background-color: #F2BFC3";
      } else if (
        this.targetsList[index]?.value >
        this.targetsList[index]?.originalValue
      )
        return "background-color: #DDF1DE";
    },
  },
};
</script>
