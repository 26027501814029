<template>
  <div>
    <div class="flex flex-row w-full  bg-grey-light-3 py-1 rounded">
      <div
        class="flex flex-row items-center focus:outline-none w-tableIndex mx-4"
      >
        <p class="text-sm-2 text-grey-light">
          NO.
        </p>
      </div>
      <div class="flex flex-grow w-1/2">
        <button
          class="flex flex-row items-center focus:outline-none"
          @click="sort('firstName', 0)"
          data-testid="header-1"
        >
          <p class="text-sm-2 text-grey-light mr-0.4">NAME</p>
          <img
            src="@/assets/img/delta-table/caret.svg"
            class="transition-all"
            :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null"
          />
        </button>
      </div>
      <div class="flex flex-grow w-1/4">
        <button
          class="flex flex-row items-center focus:outline-none"
          @click="sort('resultPermission', 1)"
          data-testid="header-2"
        >
          <p class="text-sm-2 text-grey-light mr-0.4">RESULTS PERMISSIONS</p>
          <img
            src="@/assets/img/delta-table/caret.svg"
            class="transition-all"
            :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
          />
        </button>
      </div>
    </div>
    <div>
      <VirtualList
        style="max-height: 364px; overflow-y: auto;"
        :data-key="'id'"
        :data-sources="mappedList"
        :data-component="itemComponent"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Item from "./Item.vue";
import VirtualList from "vue-virtual-scroll-list";

export default {
  name: "TeamTable",
  components: { VirtualList },
  data: () => ({
    itemComponent: Item,
    rotationMatrix: [1, 1],
    mappedList: [],
  }),
  computed: {
    ...mapState({
      participants: (state) => state.assessmentSetup.participants,
    }),
  },
  mounted() {
    this.mappedList = [...this.participants];
  },
  watch: {
    participants() {
      this.mappedList = [...this.participants];
    },
  },
  methods: {
    resetRotationMatrix(index) {
      this.rotationMatrix = this.rotationMatrix.map((item, idx) =>
        idx === index ? (item *= -1) : (item = 1)
      );
    },
    sort(key, index) {
      this.resetRotationMatrix(index);
      switch (key) {
        case "firstName":
          this.mappedList.sort(
            (a, b) =>
              this.rotationMatrix[index] *
              a.firstName.localeCompare(b.firstName)
          );
          break;
        case "resultPermission":
          this.mappedList.sort(
            (a, b) =>
              this.rotationMatrix[index] *
              (a.resultPermission - b.resultPermission)
          );
          break;

        default:
          break;
      }
    },
  },
};
</script>
