<template>
  <div>
    <div class="flex flex-row items-center w-full py-1 h-50">
      <div class="flex w-tableIndex mx-4">
        <p class="font-normal text-md text-grey-dark-2">
          {{ index + 1 }}
        </p>
      </div>
      <div class="flex flex-grow w-1/2">
        <p class="text-md text-grey-dark-1 font-semibold" v-clamp="60">
          {{ fullName }}
        </p>
      </div>
      <div class="flex flex-grow w-1/4">
        <p class="text-md text-grey-dark-2 ">
          {{ permission }}
        </p>
      </div>
    </div>
    <div
      v-if="index !== participants.length - 1"
      class="border border-grey-light-1 border-top-none"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "ListItem",
  props: {
    index: { type: Number },
    source: { type: Object, default: () => ({}) },
  },
  computed: {
    ...mapState({
      participants: (state) => state.assessmentSetup.participants,
    }),
    fullName() {
      return this.source.firstName + " " + this.source.lastName;
    },
    permission() {
      switch (this.source.resultPermission) {
        case 1:
          return "No Access";
        case 2:
          return "View Only";
        case 3:
          return "View and Download";
        default:
          return "View and Download";
      }
    },
  },
};
</script>
