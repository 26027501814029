<template>
  <div>
    <div class="flex flex-row w-full px-2">
      <div class="flex w-tableIndex justify-center mr-5 py-2">
        <p class="font-normal text-md text-grey-dark-2">
          {{ index + 1 }}
        </p>
      </div>
      <div class="flex flex-grow w-1/5 py-2">
        <p
          class="text-md text-grey-dark-1 font-semibold"
          v-clamp="$screen.breakpoint.xxxlOnly ? '35' : '20'"
        >
          {{ fullName }}
        </p>
      </div>
      <div class="flex flex-grow w-1/5 py-2">
        <p
          class="text-md text-grey-dark-2"
          v-clamp="$screen.breakpoint.xxxlOnly ? '35' : '20'"
        >
          {{ source.jobTitle }}
        </p>
      </div>
      <div class="flex flex-grow w-1/5 py-2">
        <p
          class="text-md text-grey-dark-2"
          v-clamp="$screen.breakpoint.xxxlOnly ? '35' : '20'"
        >
          {{ teams || "None" }}
        </p>
      </div>
      <div class="flex flex-grow w-1/5 py-2">
        <p
          class="text-md text-grey-dark-2"
          v-clamp="$screen.breakpoint.xxxlOnly ? '40' : '30'"
        >
          {{ source.jobRoleName }}
        </p>
      </div>
    </div>
    <div
      v-if="index !== participants.length - 1"
      class="border border-grey-light-1 border-top-none"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "ListItem",
  props: {
    index: { type: Number },
    source: { type: Object, default: () => ({}) },
  },
  computed: {
    ...mapState({
      participants: (state) => state.assessmentSetup.participants,
    }),
    teams() {
      let string = "";
      this.source.teams.map(
        (item, index) =>
          (string +=
            item.name + (index !== this.source.teams.length - 1 ? ", " : ""))
      );
      return string;
    },
    fullName() {
      return this.source.firstName + " " + this.source.lastName;
    },
  },
};
</script>
