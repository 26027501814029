<template>
  <div>
    <div class="flex justify-between items-center mb-2">
      <p class="font-bold text-mdlh text-grey-dark-1 mb-2">Communication</p>
      <Button
        text="Edit"
        type="secondary"
        size="medium"
        :iconLeft="edit"
        :onClick="handleClick"
        data-testid="edit-button"
      />
    </div>
    <div class="space-y-2">
      <div
        v-for="(email, index) in messages"
        :key="email.id"
        class="bg-grey-light-3 p-3 rounded"
      >
        <p class="font-bold text-mdh text-grey-dark-1 mb-2">
          <span>{{ index + 1 }}. </span><span>{{ messageType(email) }}</span>
        </p>
        <div class="flex flex-grow mb-5">
          <div class="w-1/5 flex-grow">
            <p class="font-medium text-sm-2 text-grey-light mb-1">
              From Address
            </p>
            <p class="text-mdh text-grey-dark-1">{{ email.from }}</p>
          </div>

          <div class="w-1/5 flex-grow">
            <p class="font-medium text-sm-2 text-grey-light mb-1">Audience</p>
            <p class="text-mdh text-grey-dark-1">
              {{ audience(email) }}
            </p>
          </div>
        </div>
        <div class="flex flex-grow mb-5">
          <div class="w-1/5 flex-grow">
            <p class="font-medium text-sm-2 text-grey-light mb-1">Subject</p>
            <p class="text-mdh text-grey-dark-1">{{ email.subject }}</p>
          </div>
          <div class="w-1/5 flex-grow">
            <p class="font-medium text-sm-2 text-grey-light mb-1">
              Send Date
            </p>
            <div class='flex space-x-4'>
              <p class="text-mdh text-grey-dark-1">
                {{ formatDate(email.sendDate) }}
              </p>
              <p
                v-if="email.messageFrequency"
                class="text-mdh text-grey-dark-1"
              >
                Repeat every {{email.nrDays && email.nrDays > 1 ? `${email.nrDays} days` : 'day'}} until close date
              </p>
            </div>
          </div>
        </div>
        <p class="font-medium text-sm-2 text-grey-light mb-1">Message</p>
        <p v-html="email.message" class="text-mdh text-grey-dark-1"></p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import edit from '@/assets/img/icons/edit.svg';
import Button from '../../../../components/Button/Button.vue';
import {
  unParseMessageAudience,
  unParseSurveyMessageType,
} from '../../utils/parsers';
export default {
  name: 'Step7',
  components: { Button },
  data: () => ({
    edit,
    unParseMessageAudience,
    unParseSurveyMessageType,
  }),
  computed: {
    ...mapState({
      messages: (state) => {
        
        console.log(state.assessmentSetup.messages); return state.assessmentSetup.messages; },
    }),
  },
  methods: {
    messageType(val) {
      return this.unParseSurveyMessageType(val.surveyMessageType);
    },
    audience(val) {
      return this.unParseMessageAudience(val.messageAudience);
    },
    formatDate(date) {
      return moment(date).format('ddd, D MMM YYYY');
    },
    handleClick() {
      this.$router.push('/assessments/communication');
    },
  },
};
</script>
