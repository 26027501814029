<template>
  <div class="mb-3">
    <div class="flex justify-between items-center">
      <p class="font-bold text-mdlh text-grey-dark-1 mb-2">
        Job Roles
      </p>
      <Button
        text="Edit"
        type="secondary"
        size="medium"
        :iconLeft="edit"
        :onClick="handleClick"
        data-testid="edit-button"
      />
    </div>
    <div class="flex mb-2">
      <p class="mr-0.4 font-medium text-mdh text-grey-dark-1">
        A total of {{ jobRoles.length }} unique Job Roles have been set for all
        participants:

        <span
          v-for="(item, index) in jobRoles"
          :key="index"
          class="font-bold text-mdh text-grey-dark-1"
        >
          {{ item }}{{ index >= jobRoles.length - 1 ? null : ", " }}
        </span>
      </p>
    </div>
    <div>
      <JobRolesPreview />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import edit from "@/assets/img/icons/edit.svg";
import Button from "../../../../components/Button/Button.vue";
import JobRolesPreview from "./PreviewTables/JobRoles";
export default {
  name: "Step3",
  components: { JobRolesPreview, Button },
  data: () => ({ edit }),
  computed: {
    ...mapState({
      participants: (state) => state.assessmentSetup.participants,
    }),
    jobRoles() {
      let list = [];
      this.participants.map((item) => {
        if (item.jobRoleName) {
          if (!list.find((el) => el == item.jobRoleName)) {
            list.push(item.jobRoleName);
          }
        }
      });
      return list;
    },
  },
  methods: {
    handleClick() {
      this.$router.push("/assessments/job-roles");
    },
  },
};
</script>

<style></style>
