export const unParseSurveyMessageType = (item) => {
  switch (item) {
    case 1:
      return "Invitation Email";
    case 2:
      return "Custom Email";
    case 3:
      return "Reminder Email";
    default:
      return "Invitation Email";
  }
};
export const unParseMessageRule = (item) => {
  switch (item) {
    case 0:
      return "Distribution Date";
    case 1:
      return "Repeat every";
    default:
      return "Distribution Date";
  }
};
export const unParseMessageAudience = (item) => {
  switch (item) {
    case 0:
      return "All Participants";
    case 1:
      return "Participants with Assessments in Progress";
    case 2:
      return "Only Managers";
    case 3:
      return "Only Employees";
    default:
      return "All Participants";
  }
};
export const unParseMessageFrequency = (item) => {
  switch (item) {
    case 0:
      return "days before launch date";
    case 1:
      return "days until assessment close date";
    default:
      return "days before launch date";
  }
};

export const parseSurveyMessageType = (item) => {
  switch (item) {
    case "Invitation Email":
      return 1;
    case "Reminder Email":
      return 2;
    case "Custom email":
      return 3;
    default:
      return 1;
  }
};
export const parseMessageRule = (item) => {
  switch (item) {
    case "Distribution Date":
      return 0;
    case "Repeat Every":
      return 1;
    default:
      return 0;
  }
};
export const parseMessageAudience = (item) => {
  switch (item) {
    case "All Participants":
      return 0;
    case "Participants with Assessments in Progress":
      return 1;
    case "Only Managers":
      return 2;
    case "Only Employees":
      return 3;
    default:
      return 0;
  }
};
export const parseMessageFrequency = (item) => {
  switch (item) {
    case "days before launch date":
      return 0;
    case "days until assessment close date":
      return 1;
    default:
      return 0;
  }
};
