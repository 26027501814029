<template>
  <div>
    <div class="flex flex-row w-full  bg-grey-light-3 py-1 px-2 rounded">
      <div
        class="flex flex-row justify-center items-center focus:outline-none w-tableIndex mr-5"
      >
        <p class="text-sm-2 text-grey-light mr-0.4">
          NO.
        </p>
      </div>
      <div class="flex flex-grow w-1/5">
        <button
          class="flex flex-row items-center focus:outline-none"
          @click="sort('firstName', 0)"
          data-testid="header-1"
        >
          <p class="text-sm-2 text-grey-light mr-0.4">NAME</p>
          <img
            src="@/assets/img/delta-table/caret.svg"
            class="transition-all"
            :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null"
          />
        </button>
      </div>
      <div class="flex flex-grow w-1/5">
        <button
          class="flex flex-row items-center focus:outline-none"
          @click="sort('jobTitle', 1)"
          data-testid="header-2"
        >
          <p class="text-sm-2 text-grey-light mr-0.4">JOB TITLE</p>
          <img
            src="@/assets/img/delta-table/caret.svg"
            class="transition-all"
            :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
          />
        </button>
      </div>
      <div class="flex flex-grow w-1/5">
        <div class="flex flex-row items-center focus:outline-none">
          <p class="text-sm-2 text-grey-light mr-0.4">TEAM</p>
        </div>
      </div>
      <div class="flex flex-grow w-1/5">
        <button
          class="flex flex-row items-center focus:outline-none"
          @click="sort('jobRoleName', 3)"
          data-testid="header-4"
        >
          <p class="text-sm-2 text-grey-light mr-0.4">JOB ROLE</p>
          <img
            src="@/assets/img/delta-table/caret.svg"
            class="transition-all"
            :class="rotationMatrix[3] !== 1 ? 'transform rotate-180' : null"
          />
        </button>
      </div>
    </div>
    <div>
      <VirtualList
        style="max-height: 371px; overflow-y: auto;"
        :data-key="'id'"
        :data-sources="mappedList"
        :data-component="itemComponent"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Item from "./Item.vue";
import VirtualList from "vue-virtual-scroll-list";

export default {
  name: "TeamTable",
  components: { VirtualList },
  data: () => ({
    itemComponent: Item,
    rotationMatrix: [1, 1, 1, 1],
    mappedList: [],
  }),
  computed: {
    ...mapState({
      participants: (state) => state.assessmentSetup.participants,
      id: (state) => state.assessmentSetup.cloneId,
    }),
  },
  methods: {
    ...mapActions({
      getParticipants: "assessmentSetup/getParticipants",
    }),
    resetRotationMatrix(index) {
      this.rotationMatrix = this.rotationMatrix.map((item, idx) =>
        idx === index ? (item *= -1) : (item = 1)
      );
    },
    sort(key, index) {
      this.resetRotationMatrix(index);
      switch (key) {
        case "firstName":
          this.mappedList.sort(
            (a, b) =>
              this.rotationMatrix[index] *
              a.firstName.localeCompare(b.firstName)
          );
          break;
        case "jobTitle":
          this.mappedList.sort(
            (a, b) =>
              this.rotationMatrix[index] * a.jobTitle.localeCompare(b.jobTitle)
          );
          break;
        case "jobRoleName":
          this.mappedList.sort(
            (a, b) =>
              this.rotationMatrix[index] *
              a.jobRoleName.localeCompare(b.jobRoleName)
          );
          break;
        default:
          break;
      }
    },
  },
  watch: {
    participants() {
      this.mappedList = [...this.participants];
    },
  },
  async mounted() {
    await this.getParticipants({ id: this.id, pageNr: 1, pageSz: 100000 });
  },
};
</script>
