<template>
  <div>
    <div class="flex justify-between items-center">
      <p class="font-bold text-mdlh text-grey-dark-1 mb-2">
        General Information
      </p>
      <Button
        text="Edit"
        type="secondary"
        size="medium"
        :iconLeft="edit"
        :onClick="handleClick"
        data-testid="edit-button"
      />
    </div>
    <p class="font-medium text-sm-2 text-grey-light mb-1">Assessment Name</p>
    <p class="text-mdh text-grey-dark-1 mb-4">{{ generalInfo.name }}</p>
    <p class="font-medium text-sm-2 text-grey-light mb-1">Question Order</p>
    <p class="text-mdh text-grey-dark-1 mb-4">
      {{ generalInfo.hasRandomisedQuestions ? "Randomized" : "Not Randomized" }}
    </p>
    <p class="font-medium text-sm-2 text-grey-light mb-1">Rules</p>
    <p class="text-mdh text-grey-dark-1 mb-3">
      {{ generalInfo.allowInMultipleSessions ? "Allow" : "Don't allow " }}
      completion in multiple sessions
    </p>
    <p class="text-mdh text-grey-dark-1 mb-4">
      {{ generalInfo.allowGoingBack ? "Allow" : "Don't allow " }} going back and
      changing answers
    </p>
    <p class="font-medium text-sm-2 text-grey-light mb-1">
      When to Publish Results
    </p>
    <p class="text-mdh text-grey-dark-1 mb-4">
      {{ permission }}
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";
import edit from "@/assets/img/icons/edit.svg";
import Button from "../../../../components/Button/Button.vue";
export default {
  components: { Button },
  name: "Step1",
  data: () => ({ edit }),
  computed: {
    ...mapState({
      generalInfo: (state) => state.assessmentSetup.generalInfo,
    }),
    permission() {
      switch (this.generalInfo.resultsPermission) {
        case 1:
          return "Never";
        case 2:
          return "Upon assessment completion";
        case 3:
          return "Upon assessment close date";
        case 4:
          return "After admin publish";
        default:
          return "Upon assessment completion";
      }
    },
  },
  methods: {
    handleClick() {
      this.$router.push("/assessments/general-information");
    },
  },
};
</script>
