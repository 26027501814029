<template>
  <div>
    <div class="flex">
      <p class="font-barlow font-medium text-xl text-grey-medium-2">
        {{ title }} {{ displayVersionForSeller(productVersion) }} /
      </p>
      <p class="font-barlow font-bold text-xl text-grey-dark-2 ml-1">
        Summary
      </p>
      <div class="flex flex-grow justify-end">
        <Button
          text="Back"
          type="primary-white"
          size="medium"
          :iconLeft="arrow"
          :rotateArrow="true"
          :onClick="goBack"
          class="mr-2"
          data-testid="back-button"
        />
        <Tooltip :content="this.publishAvalability.reason" position="bottom" :active="!this.publishAvalability.canLaunch && this.publishAvalability.reason">
          <Button
            :disabled="!this.publishAvalability.canLaunch"
            :text="mode === 'set-up' ? 'Complete Set-up' : 'Update'"
            type="primary"
            size="medium"
            class="w-155"
            :onClick="openCompleteSetupModal"
            data-testid="next-button"
          />
        </Tooltip>
      </div>
    </div>
    <div class="bg-white shadow rounded px-2 pt-3 mt-3 pb-4.5 mb-4">
      <p class="font-barlow font-bold text-mdl text-grey-light mb-2">
        Review Assessment Settings Before you Launch
      </p>
      <p class="text-mdh text-grey-dark-1 mb-3 max-w-1/2">
        If you need to make changes, you can go back and edit each section of
        the summary below.
      </p>
      <div class="border border-b-0 border-grey-light-1 mb-3" />
      <Step1 class="px-2" />
      <div class="border border-b-0 border-grey-light-1 mb-3" />
      <Step2 class="px-2" />
      <div
        v-if="assessmentType === 0"
        class="border border-b-0 border-grey-light-1 mb-3"
      />
      <Step3 v-if="assessmentType === 0" class="px-2" />
      <div class="border border-b-0 border-grey-light-1 mb-3" />
      <Step4 class="px-2" />
      <div class="border border-b-0 border-grey-light-1 mb-3" />
      <Step6 class="px-2" />
      <div class="border border-b-0 border-grey-light-1 mb-3" />
      <Step7 class="px-2" />
    </div>
  </div>
</template>

<script>
import Button from "../../../components/Button/Button.vue";
import Tooltip from "../../../components/Tooltip/Tooltip.vue";
import arrow from "@/assets/img/icons/arrow-right.svg";
import { mapState, mapActions, mapGetters } from "vuex";
import store from "@/store";
import Step1 from "../components/Summary/Step1.vue";
import Step2 from "../components/Summary/Step2.vue";
import Step3 from "../components/Summary/Step3.vue";
import Step4 from "../components/Summary/Step4.vue";
import Step6 from "../components/Summary/Step6.vue";
import Step7 from "../components/Summary/Step7.vue";
export default {
  name: "Summary",
  components: { Button, Step1, Step2, Step3, Step4, Step6, Step7, Tooltip },
  data: () => ({ arrow }),
  computed: {
    ...mapState({
      title: (state) => state.assessmentSetup.assessmentTitle,
      id: (state) => state.assessmentSetup.cloneId,
      mode: (state) => state.assessmentSetup.mode,
      generalInfo: (state) => state.assessmentSetup.generalInfo,
      publishAvalability: (state) => state.assessmentSetup.publishAvalability,
    }),
    ...mapGetters({
      isImpersonating: "auth/isImpersonating",
      isSeller: "auth/isSeller",
    }),
    assessmentType() {
      return this.generalInfo.surveyType;
    },
    productVersion(){
      return this.generalInfo.productVersion;
    },
  },
  async mounted() {
    await this.getSurveyLaunchAvalabilityInfo({id: this.id})
    await this.getGeneralInfo({ id: this.id });
    await this.getMessages({ id: this.id });
  },
  methods: {
    ...mapActions({
      getGeneralInfo: "assessmentSetup/getGeneralInfo",
      getMessages: "assessmentSetup/getMessages",
      getSurveyLaunchAvalabilityInfo: "assessmentSetup/getSurveyLaunchAvalabilityInfo",
      openModal: "modals/openModal",
    }),
    openCompleteSetupModal() {
      this.openModal({ modal: "completeSetupModal", props: { id: this.id } });
    },
    goBack() {
      this.$router.push("/assessments/communication");
    },
    displayVersionForSeller(version) {
      if (version &&  version !== "" && (this.isImpersonating || this.isSeller)) 
        return " (v" + version + ")";
      return "";
    },
  },
  beforeRouteEnter(to, _, next) {
    const title = store.getters["assessmentSetup/getAssessmentTitle"];
    to.meta.sidenav.title = title + " Setup";
    next();
  },
};
</script>
